.modalWindow {
    position: absolute;
    left: 700px;
    top: 100px;
    padding: 10px;
    background-color: #2196f3;
    border-radius: 10px;
    opacity: 0.9;
    z-index: 1000;
}

.text {
    padding: 0;
    margin: 0;
    font-size: 10px;
    color: white;
    font-weight: bold;
}
