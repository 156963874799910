.barWrapper {
  /* cursor: pointer; */
  outline: none;
}

.barWrapper:hover .barHandle {
  visibility: visible;
  opacity: 1;
}

.barHandle {
  fill: #ddd;
  cursor: ew-resize;
  opacity: 0;
  visibility: hidden;
}

/* todo remove? */
.barBackground {
  user-select: none;
  stroke-width: 0;
}
